import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'


import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU'
// import locale from 'element-ui/lib/locale/lang/uz-UZ'
Vue.use(Element, { locale })

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

import './plugins/axios'
import i18n from './plugins/i18n'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
