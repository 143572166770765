<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  box-sizing: border-box;
  // font-family: "Inter", sans-serif;
  font-family: 'Roboto', sans-serif;
  user-select: none;
}

*:not(.el-table--scrollable-x .el-table__body-wrapper, .overflow-auto)::-webkit-scrollbar{
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
*:not(.el-table--scrollable-x .el-table__body-wrapper, .overflow-auto) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
img {
  pointer-events: none;
}
.el-loading-spinner .circular {
  margin: 0 auto;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  background-color: #ffffff !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #00aaff !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.el-form--label-top .el-form-item__label {
  padding: 0 !important;
  line-height: 38px;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block !important; /* 1 */
  vertical-align: middle; /* 2 */
}
</style>
