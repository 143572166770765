import axios from "axios"
import router from "../router"
// import store from "@/store"

axios.defaults.baseURL = 'https://ucs.medias.uz/api/';

axios.interceptors.request.use(
    config => {
        const storageToken = localStorage.getItem("token")
        config.headers["Authorization"] = storageToken
            ? `Token ${storageToken}`
            : ""
        return config
    },

    error => {
        return Promise.resolve(error)
    }
)

axios.interceptors.response.use(
    function (response) {
        return response || {}
    },
    function (error) {
        const statusCode = (error.response || {}).status || -1

        if ((statusCode === 403 && error.response.data.detail === "Ushbu kirish ma'lumotining amal qilish vaqti tugagan.") || (statusCode === 404 && error.response.data.detail === "Ushbu ma'lumotlar bilan foydalanuvchi topilmadi.")) {
            delete axios.defaults.headers.common["Authorization"]
            localStorage.removeItem("token");
            router.push('/login')
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)
